<template lang="pug">
drawer(:dismissRoute="dismissRoute")
  .drawer-title
    el-row(:gutter='$rowGutter')
      el-col(:span='12')
        i.mat-icon payment
        | Make payment
      el-col.alignR(:span='12')
        | Invoice# {{ this.invoiceId }}

  el-form(
    :label-width='$labelWidth', label-position='right', ref='paymentForm'
    :model='payment', :rules='rules'
    )

    el-form-item(label='Date', prop='date')
      el-date-picker(
        v-model='payment.date', placeholder='Select a date', value-format='yyyy-MM-dd', class="make-payment_date"
      )

    el-form-item(label='Amount of payment', prop='sum')
      el-input(v-model='payment.sum')
        template(slot='prepend')
          | $

    el-form-item(label='Notes', prop='note')
      el-input(
      type='textarea' v-model='payment.note' max='250',
      :autosize='{ minRows: 2, maxRows: 7 }'
      )

  div.buttons-section
    el-button(type='primary' @click='onSubmit', :loading='submitting')
      | Save
    el-button(@click='onCancel')
      | Cancel
</template>

<script>
export default {
  name: 'MakePayment',

  data() {
    return {
      dismissRoute: this.$route.matched[1].path || '',
      rules: this.buildFormRules(),
      submitting: false,
      payment: {
        date: '',
        sum: +((+this.$route.query.balance / 100).toFixed(2)),
        note: ''
      }
    }
  },

  methods: {
    buildFormRules() {
      return {
        note: {
          max: 250, message: 'Notes cannot exceed 250 characters', trigger: 'change'
        },
        sum: {
          required: true, type: 'number',
          message: 'Please input a valid sum in dollars',
          trigger: 'change',
          transform: parseFloat
        },
        date: {
          required: true, message: 'Please pick a date', trigger: 'change'
        }
      }
    },

    onCancel() {
      this.$router.push(this.dismissRoute)
    },

    onSubmit() {
      this.$refs.paymentForm.validate((valid) => {
        if (valid) {
          this.submitForm()
        } else {
          this.$message.error('Some validations failed, please check your inputs')
          return false;
        }
      })
    },

    submitForm() {
      this.submitting = true;
      const request = {
        invoice_id: this.invoiceId,
        date: this.payment.date,
        notes: this.payment.note
      };
      request.total_cents = this.payment.sum ? +((+this.payment.sum * 100).toFixed()) : 0;

      this.$simplecater.post(`/payments`, request).then(() => {
        this.onCancel();
        this.submitting = false;
      }
      ).catch(() => {
        this.$message.error("Failed to make payment")
        this.submitting = false;
      })
    },
  },

  computed: {
    creatingAddress: function () {
      return this.request.address_id === 'new';
    },
    creatingContact: function () {
      return this.request.contact_id === 'new';
    },
    invoiceId: function () { return +this.$route.query.id; }
  }
}
</script>


<style scoped lang="sass">
  .collapse-item__tip
    font-style: italic
    opacity: 0.8
    display: inline-block
    float: right
    margin-right: 1rem
    font-weight: 600
    color: $colorPrimary
    &:hover
      opacity: 1
      
  .alignR
    text-align: right

  .drawer .el-collapse-item__header, .drawer .el-collapse-item__wrap
    background-color: #FAFAFA

  .new-request__search-cont
    padding: 0 0 30px

  .new-request__search
    width: 100%
  .buttons-section
    margin-bottom: 20px
    text-align: center

  .make-payment_date
    width: 100%
</style>
