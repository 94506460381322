<template lang='pug'>
.main-container
  .view-content
    el-card
      div(slot='header')
        i.mat-icon settings
        | Settings

      el-table(:data='settings', v-loading='settings == null')
        el-table-column(prop='name', label='Name')
        el-table-column(prop='value', label='Value' v-slot="scope")
          el-input(v-model='scope.row.value', placeholder='Value', )
        el-table-column(label='Actions')
          template(slot-scope='scope')
            el-button(type='text', size='small', @click='saveSetting(scope.row)') Save
</template>

<script>
export default {
  name: 'SettingsList',
  data() {
    return {
      settings: null,
    }
  },
  methods: {
    getSettings() {
      return this.$simplecater.get("/settings").then((response) => {
        this.settings = response.data.data;
      });
    },
    saveSetting(setting) {
      const params = {
        key: setting.key,
        value: setting.value
      }

      return this.$simplecater.put(`/settings/`, params).then(() => {
        this.$message.success('Setting saved');
      }).catch(() => {
        this.$message.error("Failed to update the setting")
      })
    }
  },
  created() {
    this.getSettings()
  }
}
</script>