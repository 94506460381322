<template lang="pug">
drawer(dismissRoute='/staff/vendors')
  .drawer-title
    i.mat-icon add_circle
    | New Vendor

  el-form(
    :label-width='$labelWidth', label-position='right', ref='requestForm'
    v-loading='loadingForm', :model='vendor', :rules='rules'
    )

    // NAME
    el-form-item(label='Name', prop='name')
      el-input(v-model='vendor.name')

    // CUISINE_ID
    el-form-item(label='Cuisine', prop='cuisine_id')
      el-select(v-model='vendor.cuisine_id', filterable, :filter-method="filteringCuisines" placeholder='Choose one', class='full-width-select')
        el-option(v-for='item in filteredCuisines', :key='item.id', :label='item.name', :value='item.id')

    // FEE_CENTS
    el-form-item(label='Fee', prop='fee_cents')
      el-input(v-model='vendor.fee_cents')
        template(slot='prepend')
          | $

    // FEE_PERCENTAGE
    el-form-item(label='Percentage Fee', prop='fee_percentage')
      el-input(v-model='vendor.fee_percentage')
        template(slot='append')
          | %

    // GLOBAL_DISCOUNT_PERCENTAGE
    el-form-item(label='Global Discount Percentage', prop='global_discount_percentage')
      el-input(v-model='vendor.global_discount_percentage')
        template(slot='append')
          | %

    // ITEM_MARGIN_PERCENTAGE
    el-form-item(label='Item Margin Percentage', prop='item_margin_percentage')
      el-input(v-model='vendor.item_margin_percentage')
        template(slot='append')
          | %

    // PROCESSING_FEE_PERCENTAGE
    el-form-item(label='Processing Fee Percentage', prop='processing_fee_percentage')
      el-input(v-model='vendor.processing_fee_percentage')
        template(slot='append')
          | %

    // Rewards checkbox
    el-form-item(label='Rewards eligible', prop='rewards')
      el-checkbox(v-model='vendor.rewards')

    // BUTTONS
    el-form-item
      el-button(type='primary' @click='onSubmit', :loading='submitting')
        | Save
      el-button(@click='onCancel')
        | Cancel
</template>

<script>
import { filteredDropdown } from '@/mixins/filteredDropdown'

const mixins = [filteredDropdown('cuisines', (input) => ({ name }) => name.toLowerCase().includes(input.toLowerCase()))]

const emptyVendor = {
  active: true,
  cuisine_id: null,
  fee_cents: null,
  fee_percentage: null,
  global_discount_percentage: null,
  item_margin_percentage: null,
  name: null,
  processing_fee_percentage: null,
  rewards: false,
}

function buildFormRules() {
  return {
    name: {
      max: 100, required: true, message: 'Name cannot exceed 100 characters', trigger: 'change'
    },
    active: {
      type: 'boolean', required: true, message: 'Choose whether vendor is active or not', trigger: 'change'
    },
    cuisine_id: {
      type: 'integer', required: true, trigger: 'change',
      message: 'Please select a cuisine'
    },
    fee_cents: {
      type: 'number',
      message: 'Please input a valid fee in dollars',
      trigger: 'blur',
      transform: parseFloat
    },
    fee_percentage: {
      type: 'number',
      message: 'Please input a valid percentage',
      trigger: 'blur',
      transform: parseFloat
    },
    global_discount_percentage: {
      type: 'number',
      message: 'Please input a valid percentage',
      trigger: 'blur',
      transform: parseFloat
    },
    item_margin_percentage: {
      type: 'number',
      message: 'Please input a valid percentage',
      trigger: 'blur',
      transform: parseFloat
    },
    processing_fee_percentage: {
      type: 'number',
      message: 'Please input a valid percentage',
      trigger: 'blur',
      transform: parseFloat
    },
  }
}

function getVendor() {
  this.$simplecater.get('/vendors/' + this.id).then((r) => {
    let vendor = r.data.data
    vendor.fee_cents = vendor.fee_cents / 100
    this.vendor = vendor
  })
}

function data() {
  if (this.id) {
    this.getVendor()
  }

  return {
    rules: buildFormRules(),
    loadingForm: true,
    dataForSubmit: null,
    submitting: false,
    vendor: emptyVendor,
    cuisines: [],
    filteredCuisines: [],
    preferences: {},
    isContactValid: false,
  }
}

function created() {
  Promise.all([
    this.getCuisines(),
  ]).then(() => {
    this.loadingForm = false
  }).catch(() => {
    this.$message.error('Failed loading data')
  })
}

function getCuisines() {
  return this.$simplecater.get('/cuisines').then((r) => {
    this.cuisines = r.data.data
  })
}

function onCancel() {
  this.$router.push('/staff/vendors')
}

// This the click event handles, only triggers submission if validations pass
function onSubmit() {
  this.$refs.requestForm.validate((valid) => {
    if (valid) {
      this.submitForm()
    } else {
      this.$message.error('Some validations failed, please check your inputs')
      return false;
    }
  })
}

// This the actual submission after validation
function submitForm() {
  this.submitting = true
  this.dataForSubmit = Object.assign({}, this.vendor)
  this.dataForSubmit.fee_cents = this.vendor.fee_cents * 100
  this.dataForSubmit.fee_percentage = parseFloat(this.dataForSubmit.fee_percentage)
  this.dataForSubmit.global_discount_percentage = parseFloat(this.dataForSubmit.global_discount_percentage)
  this.dataForSubmit.item_margin_percentage = parseFloat(this.dataForSubmit.item_margin_percentage)
  this.dataForSubmit.processing_fee_percentage = parseFloat(this.dataForSubmit.processing_fee_percentage)

  let verb = 'post'
  let url = '/vendors'
  
  if (this.id) {
    verb = 'put'
    url += '/' + this.id
  }

  this.$simplecater[verb](url, this.dataForSubmit).then(() => {
    // SUBMIT SUCCEEDED
    this.$message("Vendor has been " + ((verb === 'post') ? 'created' : 'saved'))
    this.$router.push("/staff/vendors")
  }).catch(
    // SUBMIT FAILED
    this.$handleErrorFor('Creating / Editing vendor').bind(this)
  ).finally(() => {
    this.submitting = false
  })
}

const computed = {}

const methods = {
  submitForm,
  onCancel,
  onSubmit,
  getVendor,
  getCuisines,
}

let props = {
  id: {}
}

export default { mixins, props, data, created, methods, computed }
</script>
