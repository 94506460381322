<template>
  <div class="main-container" v-loading='!request'>
    <div class="view-content">
      <div class="request-header">
        <h2 class="page-title" v-if="request">{{ request.name }}</h2>
        <div class="request-header-req-info">
          <div class="req-number">Request #{{ request ? request.id : '' }}</div>
          <status-tag v-if="request" :status="request.status"></status-tag>
        </div>
      </div>
      <request v-if="request" :request="request"></request>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Request from './blocks/UserRequest.vue'
import StatusTag from '../common/UserStatusTag.vue'

export default {
  name: 'UserRequestDetails',

  data() {
    return {
      request: null,
      loading: true,
    }
  },

  methods: {
    fetchData() {
      if (this.$route.matched[1].path !== '/user/requests') {
        this.$simplecater.get('/requests/' + this.$route.params.id).then((r) => {
          let request = r.data.data
          request.estimates = _.sortBy(request.estimates, 'id');
          this.request = request
        }).catch(() => {
          this.$message.error("Failed to load the request")
        })
      }
    },
  },
  created() {
    this.fetchData()
    this.$bus.$on('request-created', this.fetchData)
    this.$bus.$on('estimate-accepted', this.fetchData)
  },

  components: { Request, StatusTag }
}
</script>

<style scoped lang="sass">
  .page-title
    margin-bottom: 1.75rem  
  .request-name
    display: inline-block
    font-weight: 600
    margin: 0 0.5rem
  .secondary
    // display: inline-block
    color: #888
    font-size: 0.8em
    margin-left: 1.7rem
    .v-divider
      display: inline-block
      width: 1px
      background: #ccc
      height: 1.5em
      margin: 0 0.5rem
      vertical-align: middle
  .request-header
    display: flex
    justify-content: space-between
    align-items: flex-start
    &-req-info
      max-width: fit-content
      margin-top: 26px
    .req-number
      font-size: .875rem
      color: #A2A29D
      margin-left: 7px
      margin-bottom: 8px

</style>
