<template>
  <div>
    <el-card>
      <user-stepper :steps="steps" :active="[request.status] || 0" />
    </el-card>
    <!-- <request-card :request="request" @editClick="onEditDialog($event)" @cancelClick="markCancelled(request.id)"/> -->
    <!--:closed="request.status === 'CLOSED' || request.status === 'CANCELLED' || request.status === 'ESTIMATE_ORDERED'"-->
    <div class="requst-page-content">
      <div class="delivery-info">
        <div class="page-sub-title">Request Details</div>
        <delivery-information :contact="contact" :address="address" :suite="request.suite"
          :deliveryTime="request.delivery_time" :deliveryDate="request.formatted_delivery_date" />
      </div>
      <div class="estimates-info">
        <user-estimates :requestStatus="request.status" :createAt="createAt" @onPushOrderClick="viewOrder()">
          <div>
            <user-tabs :tabsData="request.estimates">
              <template v-for="(estimate, index) in request.estimates" v-slot:[index]>
                <user-estimate-viewer 
                  @change="onEstimateChange" 
                  :remote-id="estimate.id" 
                  :index="index"
                  :request-id="request.id" 
                  :people="request.people" 
                  :request="request" 
                  :address="address"
                  :contact="contact"
                  :key="estimate.id"
                >
                </user-estimate-viewer>
              </template>
            </user-tabs>
          </div>
        </user-estimates>
      </div>
    </div>

    <div class="requst-page-additional-info">
      <div class="preferences-info">
        <div class="page-sub-title">Preferences</div>
        <el-card>
          <user-preferences :preferences="request.preferences" />
        </el-card>
      </div>
      <div class="notes-info">
        <div class="page-sub-title">Notes</div>
        <el-card>
          <div class="no-notes" v-if="!request.notes">N/A</div>
          <div v-else style="min-height: 12.45rem">{{ request.notes }}</div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import DeliveryInformation from './DeliveryInformation.vue'
import Request from './Request.vue'
import RequestCard from './RequestCard.vue'
import UserEstimates from './UserEstimates.vue'
import UserEstimateViewer from './UserEstimateViewer.vue'
import UserPreferences from './UserPreferences.vue'
//import RequestList from './RequestList.vue'
import UserStepper from './UserStepper.vue'
import UserTabs from './UserTabs.vue'


const mixins = [Request] //RequestList
const components = { UserStepper, RequestCard, DeliveryInformation, UserEstimates, UserPreferences, UserTabs, UserEstimateViewer }
// "ESTIMATES_AVAILABLE"
// "WAITING_FOR_ESTIMATES"
// "ESTIMATE_ORDERED"
function data() {
  return {
    steps: [
      {
        completeIcon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path class="step-icon" d="M11.2827 3.45332C11.5131 2.98638 11.6284 2.75291 11.7848 2.67831C11.9209 2.61341 12.0791 2.61341 12.2152 2.67831C12.3717 2.75291 12.4869 2.98638 12.7174 3.45332L14.9041 7.88328C14.9721 8.02113 15.0061 8.09006 15.0558 8.14358C15.0999 8.19096 15.1527 8.22935 15.2113 8.25662C15.2776 8.28742 15.3536 8.29854 15.5057 8.32077L20.397 9.03571C20.9121 9.11099 21.1696 9.14863 21.2888 9.27444C21.3925 9.38389 21.4412 9.5343 21.4215 9.68377C21.3988 9.85558 21.2124 10.0372 20.8395 10.4004L17.3014 13.8464C17.1912 13.9538 17.136 14.0076 17.1004 14.0715C17.0689 14.128 17.0487 14.1902 17.0409 14.2545C17.0321 14.3271 17.0451 14.403 17.0711 14.5547L17.906 19.4221C17.994 19.9355 18.038 20.1922 17.9553 20.3445C17.8833 20.477 17.7554 20.57 17.6071 20.5975C17.4366 20.6291 17.2061 20.5078 16.7451 20.2654L12.3724 17.9658C12.2361 17.8942 12.168 17.8584 12.0962 17.8443C12.0327 17.8318 11.9673 17.8318 11.9038 17.8443C11.832 17.8584 11.7639 17.8942 11.6277 17.9658L7.25492 20.2654C6.79392 20.5078 6.56341 20.6291 6.39297 20.5975C6.24468 20.57 6.11672 20.477 6.04474 20.3445C5.962 20.1922 6.00603 19.9355 6.09407 19.4221L6.92889 14.5547C6.95491 14.403 6.96793 14.3271 6.95912 14.2545C6.95132 14.1902 6.93111 14.128 6.89961 14.0715C6.86402 14.0076 6.80888 13.9538 6.69859 13.8464L3.16056 10.4004C2.78766 10.0372 2.60121 9.85558 2.57853 9.68377C2.55879 9.5343 2.60755 9.38389 2.71125 9.27444C2.83044 9.14863 3.08797 9.11099 3.60304 9.03571L8.49431 8.32077C8.64642 8.29854 8.72248 8.28742 8.78872 8.25662C8.84736 8.22935 8.90016 8.19096 8.94419 8.14358C8.99391 8.09006 9.02793 8.02113 9.09597 7.88328L11.2827 3.45332Z" stroke="#125F43" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                       </svg>`,
        activeIcon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path class="step-icon" d="M11.2827 3.45332C11.5131 2.98638 11.6284 2.75291 11.7848 2.67831C11.9209 2.61341 12.0791 2.61341 12.2152 2.67831C12.3717 2.75291 12.4869 2.98638 12.7174 3.45332L14.9041 7.88328C14.9721 8.02113 15.0061 8.09006 15.0558 8.14358C15.0999 8.19096 15.1527 8.22935 15.2113 8.25662C15.2776 8.28742 15.3536 8.29854 15.5057 8.32077L20.397 9.03571C20.9121 9.11099 21.1696 9.14863 21.2888 9.27444C21.3925 9.38389 21.4412 9.5343 21.4215 9.68377C21.3988 9.85558 21.2124 10.0372 20.8395 10.4004L17.3014 13.8464C17.1912 13.9538 17.136 14.0076 17.1004 14.0715C17.0689 14.128 17.0487 14.1902 17.0409 14.2545C17.0321 14.3271 17.0451 14.403 17.0711 14.5547L17.906 19.4221C17.994 19.9355 18.038 20.1922 17.9553 20.3445C17.8833 20.477 17.7554 20.57 17.6071 20.5975C17.4366 20.6291 17.2061 20.5078 16.7451 20.2654L12.3724 17.9658C12.2361 17.8942 12.168 17.8584 12.0962 17.8443C12.0327 17.8318 11.9673 17.8318 11.9038 17.8443C11.832 17.8584 11.7639 17.8942 11.6277 17.9658L7.25492 20.2654C6.79392 20.5078 6.56341 20.6291 6.39297 20.5975C6.24468 20.57 6.11672 20.477 6.04474 20.3445C5.962 20.1922 6.00603 19.9355 6.09407 19.4221L6.92889 14.5547C6.95491 14.403 6.96793 14.3271 6.95912 14.2545C6.95132 14.1902 6.93111 14.128 6.89961 14.0715C6.86402 14.0076 6.80888 13.9538 6.69859 13.8464L3.16056 10.4004C2.78766 10.0372 2.60121 9.85558 2.57853 9.68377C2.55879 9.5343 2.60755 9.38389 2.71125 9.27444C2.83044 9.14863 3.08797 9.11099 3.60304 9.03571L8.49431 8.32077C8.64642 8.29854 8.72248 8.28742 8.78872 8.25662C8.84736 8.22935 8.90016 8.19096 8.94419 8.14358C8.99391 8.09006 9.02793 8.02113 9.09597 7.88328L11.2827 3.45332Z" stroke="#CFA531" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                       </svg>`,
        icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path class="step-icon" d="M11.2827 3.45332C11.5131 2.98638 11.6284 2.75291 11.7848 2.67831C11.9209 2.61341 12.0791 2.61341 12.2152 2.67831C12.3717 2.75291 12.4869 2.98638 12.7174 3.45332L14.9041 7.88328C14.9721 8.02113 15.0061 8.09006 15.0558 8.14358C15.0999 8.19096 15.1527 8.22935 15.2113 8.25662C15.2776 8.28742 15.3536 8.29854 15.5057 8.32077L20.397 9.03571C20.9121 9.11099 21.1696 9.14863 21.2888 9.27444C21.3925 9.38389 21.4412 9.5343 21.4215 9.68377C21.3988 9.85558 21.2124 10.0372 20.8395 10.4004L17.3014 13.8464C17.1912 13.9538 17.136 14.0076 17.1004 14.0715C17.0689 14.128 17.0487 14.1902 17.0409 14.2545C17.0321 14.3271 17.0451 14.403 17.0711 14.5547L17.906 19.4221C17.994 19.9355 18.038 20.1922 17.9553 20.3445C17.8833 20.477 17.7554 20.57 17.6071 20.5975C17.4366 20.6291 17.2061 20.5078 16.7451 20.2654L12.3724 17.9658C12.2361 17.8942 12.168 17.8584 12.0962 17.8443C12.0327 17.8318 11.9673 17.8318 11.9038 17.8443C11.832 17.8584 11.7639 17.8942 11.6277 17.9658L7.25492 20.2654C6.79392 20.5078 6.56341 20.6291 6.39297 20.5975C6.24468 20.57 6.11672 20.477 6.04474 20.3445C5.962 20.1922 6.00603 19.9355 6.09407 19.4221L6.92889 14.5547C6.95491 14.403 6.96793 14.3271 6.95912 14.2545C6.95132 14.1902 6.93111 14.128 6.89961 14.0715C6.86402 14.0076 6.80888 13.9538 6.69859 13.8464L3.16056 10.4004C2.78766 10.0372 2.60121 9.85558 2.57853 9.68377C2.55879 9.5343 2.60755 9.38389 2.71125 9.27444C2.83044 9.14863 3.08797 9.11099 3.60304 9.03571L8.49431 8.32077C8.64642 8.29854 8.72248 8.28742 8.78872 8.25662C8.84736 8.22935 8.90016 8.19096 8.94419 8.14358C8.99391 8.09006 9.02793 8.02113 9.09597 7.88328L11.2827 3.45332Z" stroke="#9AA1AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                       </svg>`,
        number: 1,
        title: 'Create Estimates',
        value: 'WAITING_FOR_ESTIMATES'
      },
      {
        activeIcon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path class="step-icon" d="M11.6668 9.16663H6.66683M8.3335 12.5H6.66683M13.3335 5.83329H6.66683M16.6668 8.74996V5.66663C16.6668 4.26649 16.6668 3.56643 16.3943 3.03165C16.1547 2.56124 15.7722 2.17879 15.3018 1.93911C14.767 1.66663 14.067 1.66663 12.6668 1.66663H7.3335C5.93336 1.66663 5.2333 1.66663 4.69852 1.93911C4.22811 2.17879 3.84566 2.56124 3.60598 3.03165C3.3335 3.56643 3.3335 4.26649 3.3335 5.66663V14.3333C3.3335 15.7334 3.3335 16.4335 3.60598 16.9683C3.84566 17.4387 4.22811 17.8211 4.69852 18.0608C5.2333 18.3333 5.93336 18.3333 7.3335 18.3333H9.5835M18.3335 18.3333L17.0835 17.0833M17.9168 15C17.9168 16.6108 16.611 17.9166 15.0002 17.9166C13.3893 17.9166 12.0835 16.6108 12.0835 15C12.0835 13.3891 13.3893 12.0833 15.0002 12.0833C16.611 12.0833 17.9168 13.3891 17.9168 15Z" stroke="#CFA531" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                       </svg>`,
        completeIcon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path class="step-icon" d="M11.6668 9.16663H6.66683M8.3335 12.5H6.66683M13.3335 5.83329H6.66683M16.6668 8.74996V5.66663C16.6668 4.26649 16.6668 3.56643 16.3943 3.03165C16.1547 2.56124 15.7722 2.17879 15.3018 1.93911C14.767 1.66663 14.067 1.66663 12.6668 1.66663H7.3335C5.93336 1.66663 5.2333 1.66663 4.69852 1.93911C4.22811 2.17879 3.84566 2.56124 3.60598 3.03165C3.3335 3.56643 3.3335 4.26649 3.3335 5.66663V14.3333C3.3335 15.7334 3.3335 16.4335 3.60598 16.9683C3.84566 17.4387 4.22811 17.8211 4.69852 18.0608C5.2333 18.3333 5.93336 18.3333 7.3335 18.3333H9.5835M18.3335 18.3333L17.0835 17.0833M17.9168 15C17.9168 16.6108 16.611 17.9166 15.0002 17.9166C13.3893 17.9166 12.0835 16.6108 12.0835 15C12.0835 13.3891 13.3893 12.0833 15.0002 12.0833C16.611 12.0833 17.9168 13.3891 17.9168 15Z" stroke="#125F43" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                       </svg>`,
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path class="step-icon" d="M11.6668 9.16663H6.66683M8.3335 12.5H6.66683M13.3335 5.83329H6.66683M16.6668 8.74996V5.66663C16.6668 4.26649 16.6668 3.56643 16.3943 3.03165C16.1547 2.56124 15.7722 2.17879 15.3018 1.93911C14.767 1.66663 14.067 1.66663 12.6668 1.66663H7.3335C5.93336 1.66663 5.2333 1.66663 4.69852 1.93911C4.22811 2.17879 3.84566 2.56124 3.60598 3.03165C3.3335 3.56643 3.3335 4.26649 3.3335 5.66663V14.3333C3.3335 15.7334 3.3335 16.4335 3.60598 16.9683C3.84566 17.4387 4.22811 17.8211 4.69852 18.0608C5.2333 18.3333 5.93336 18.3333 7.3335 18.3333H9.5835M18.3335 18.3333L17.0835 17.0833M17.9168 15C17.9168 16.6108 16.611 17.9166 15.0002 17.9166C13.3893 17.9166 12.0835 16.6108 12.0835 15C12.0835 13.3891 13.3893 12.0833 15.0002 12.0833C16.611 12.0833 17.9168 13.3891 17.9168 15Z" stroke="#9AA1AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                       </svg>`,
        number: 2,
        title: 'Review Estimates',
        value: 'ESTIMATES_AVAILABLE'
      },
      {
        icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path class="step-icon" d="M20 12.5V6.8C20 5.11984 20 4.27976 19.673 3.63803C19.3854 3.07354 18.9265 2.6146 18.362 2.32698C17.7202 2 16.8802 2 15.2 2H8.8C7.11984 2 6.27976 2 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4 4.27976 4 5.11984 4 6.8V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.11984 22 8.8 22H12M14 11H8M10 15H8M16 7H8M14.5 19L16.5 21L21 16.5" stroke="#9AA1AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                       </svg>`,
        activeIcon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path class="step-icon" d="M20 12.5V6.8C20 5.11984 20 4.27976 19.673 3.63803C19.3854 3.07354 18.9265 2.6146 18.362 2.32698C17.7202 2 16.8802 2 15.2 2H8.8C7.11984 2 6.27976 2 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4 4.27976 4 5.11984 4 6.8V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.11984 22 8.8 22H12M14 11H8M10 15H8M16 7H8M14.5 19L16.5 21L21 16.5" stroke="#CFA531" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                       </svg>`,
        completeIcon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path class="step-icon" d="M20 12.5V6.8C20 5.11984 20 4.27976 19.673 3.63803C19.3854 3.07354 18.9265 2.6146 18.362 2.32698C17.7202 2 16.8802 2 15.2 2H8.8C7.11984 2 6.27976 2 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4 4.27976 4 5.11984 4 6.8V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.11984 22 8.8 22H12M14 11H8M10 15H8M16 7H8M14.5 19L16.5 21L21 16.5" stroke="#125F43" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                       </svg>`,
        number: 3,
        title: 'Submit Order',
        value: 'ESTIMATE_ORDERED'
      },

    ]
  }
}

const props = ['request']

function onEditDialog(id) {
  const target = `/user/requests/${id}/edit-req`;
  this.$router.push(target)
}

function markCancelled(id) {
  const target = `/requests/${id}/mark_cancelled`
  this.$simplecater.post(target).then(() => {
    this.$message("The request has been cancelled")
    //this.getData()
    this.$router.push('/user/requests/')
  }).catch(() => {
    this.$message.error("Cancelling failed")
  })
}

const methods = { onEditDialog, markCancelled }

const computed = {
  createAt() {
    if (this.request && this.request.order) {
      return this.request.order.created_at === "Invalid date" ? 'earlier' : this.request.order.created_at
    }
    return ''
  }
}

export default { data, mixins, components, methods, props, computed }
</script>

<style scoped lang="sass">
  .el-row
    display: flex
    flex-direction: rows
    [class^="el-col"]
      & > div
        height: 100%
  .secondary
    padding: 2rem 0
    color: #666
    text-align: center
  .delivery-contact
    .name
      font-weight: 600
  .delivery-address
    font-size: 0.9em
    margin-top: 0.5rem
  .no-notes
    font-style: italic
    color: #999
  .already-ordered
    color: #999
    font-size: 0.9em
    text-align: center
    padding: 2rem 0
    .el-button
      margin-top: 1rem
  .classic
    white-space: normal
  .page-sub-title
    font-weight: 500
    font-size: 1rem
    letter-spacing: -0.03em
    color: #A2A29D 
  .requst-page-content
    display: flex
    flex-direction: column
    max-width: 100%
    justify-content: space-between
  .page-sub-title
    margin: 36px 0 16px 0
  .delivery-info
    flex-grow: 0.3
  .estimates-info
    flex-grow: 1
  .requst-page-additional-info
    display: flex
    gap: 2.25rem
  .notes-info
    flex: 0.5
  .preferences-info
    flex: 0.5     
</style>
