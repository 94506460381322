<template lang="pug">
.main-container
  .view-content
    div
      el-card
        div(slot='header')
          i.mat-icon person
          | Customers
          el-button(type="primary", @click="startCreatingCustomer", style='float: right')
            i.mat-icon add_circle
            | New customer
        el-col(:span="9")
          el-input(class="el-col-9", v-model="filterCustomer", placeholder="Search by name")
        el-table(:data='customers', v-loading='customers == null')
          el-table-column(type='expand')
            template(slot-scope='props')
              customer(:backend-data='props.row', @deleted='getCustomers')
          el-table-column(label='Account name', prop='account_name')
          el-table-column(label='Location')
            template(slot-scope='scope', v-if='scope.row.city')
              | {{scope.row.city}}, {{scope.row.state}} {{scope.row.zip_code}}

  el-dialog(:visible.sync='creatingCustomer', title='Create customer')

    el-form(ref="newCustomer", :model="newCustomer", label-width="150px", v-if='newCustomer != null', :rules="createCustomerRules")

      el-form-item(label='Account name', prop="account_name")
        el-input(placeholder="", v-model='newCustomer.account_name')
      el-form-item(label='Email', prop="email")
        el-input(placeholder="", v-model='newCustomer.email')
      el-form-item(label='Password', prop="password")
        el-input(placeholder="", v-model='newCustomer.password')
      el-form-item(align="right")
        el-button(type='primary', @click='submitForm')
          | Create customer


  el-col()
    el-pagination(
      v-if="pagination.pages > 1"
      @current-change="onPaginationChange"
      :page-size="pagination.per_page",
      :pager-count="5",
      layout="prev, pager, next",
      :total="pagination.total")
  router-view
</template>

<script>
export default {
  name: "CustomersList",
  
  data() {
    return {
      customers: null,
      creatingCustomer: false,
      newCustomer: null,
      filterCustomer: "",
      createCustomerRules: {
        email: [
          {
            required: true,
            message: "Please input the email",
            trigger: "blur"
          },
          {
            type: "email",
            message: "Please input correct email address",
            trigger: ["blur", "change"]
          }
        ],
        account_name: [
          {
            required: true,
            message: "Please input the account name",
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: "Please input the password",
            trigger: "blur"
          },
          {
            min: 8,
            message: "Password should contain at least 8 characters",
            trigger: ["blur", "change"]
          }
        ]
      },
      pagination: {
        per_page: null,
        current: null,
        pages: null,
        total: null
      }
    };
  },

  components: { Customer: () => import("./blocks/Customer.vue") },

  methods: {
    async getCustomers(current_page) {
      !!this.customers && (this.customers = null);
      let params = current_page ? { params: { page: current_page } } : {};
      const url = this.filterCustomer.trim() ? `/customers?account_name=${this.filterCustomer.trim()}` : "/customers";
      const { data, pagination } = (
        await this.$simplecater.get(url, params)
      ).data;
      this.customers = data;
      this.pagination = pagination;
    },

    submitForm() {
      this.$refs.newCustomer.validate(valid => {
        if (!valid) return false;

        this.createCustomer();
      });
    },

    createCustomer() {
      return this.$simplecater
        .post("/customers", this.newCustomer)
        .then(r => {
          const customer = r.data.data;
          this.getCustomers();
          this.creatingCustomer = false;
          console.log("NEW CUSTOMER RESULT", customer);
        })
        .catch(this.$handleErrorFor("create customer").bind(this));
    },

    startCreatingCustomer() {
      this.newCustomer = {};
      this.creatingCustomer = true;
    },

    onPaginationChange(current_page) {
      this.getCustomers(current_page);
    }
  },

  watch: {
    $route() {
      this.getCustomers();
    },

    filterCustomer() {
      this.getCustomers();
    }
  },

  created() {
    this.getCustomers();
  }
};
</script>
