<template lang="pug">
.main-container
  .view-content

    el-card.customer-points(body-style="padding: 0")
      div(slot='header')
        i.mat-icon redeem
        router-link(to='/staff/rewards') Rewards
        |  &gt; 
        .customer-name(v-if='customer') {{ customer.account_name }}

      el-row.points-balance(v-if='points' )
        el-col.points.amount(v-model='points.amount' :span='12')
          | {{ formatNumber(points.amount) }} 
          h3 Points
        el-col.points.dollars(v-model='points.amount' :span='12')
          | {{ formatPointsIntoMoney(points.amount) }} 
          h3 {{ exchangeRate }} points = $1 of rewards

      el-row(v-if="points" style="padding: 19px 24px 24px 24px")
        el-col.points-form
          el-form(:model='adjustPoints' class='points-form' :rules='rules' ref='pointsForm')
            el-form-item(label='Adjust points:' class='row is-no-asterisk' style="margin-bottom: 12px;" prop="addedPoints")
              el-input(
                v-model='adjustPoints.addedPoints'
                placeholder='0' 
                style="width:108px; height:48px; float: right; text-align: center;"
              )

            .row(style="margin-bottom: 24px;")
              label
                | New Point Balance:
              label.info
                | {{ newPointsBalance }}

            el-row.buttons-section
              el-col(:span='8', :offset='16', style='text-align: right')
                el-button.points-button(type='primary', @click='onSubmit', :loading='submitting') Submit

    customer-rewards-activities(:key='refreshActivities')
</template>

<script>
import CustomerRewardsActivities from './CustomerRewardsActivities.vue';

export default {
  name: 'CustomerRewards',

  data() {
    return {
      customer: null,
      rewards: null,
      points: null,
      settings: null,
      exchangeRate: 1,
      adjustPoints: {
        addedPoints: null,
      },
      submitting: false,
      rules: {
        addedPoints: {
          required: true,
          message: ' ',
          trigger: 'blur',
          pattern: /\d+/,
        }
      },
      refreshActivities: 0,
    }
  },

  methods: {
    getCustomer() {
      this.$simplecater.get(`/points/customers/${this.$route.params.id}/info`).then((r) => {
        this.customer = r.data.data;
      }).catch(() => {
        this.$message.error("Failed to load the customer");
      })
    },

    getPoints() {
      this.$simplecater.get(`points/customers/${this.$route.params.id}`).then((r) => {
        this.points = r.data.data;
      }).catch(() => {
        this.$message.error("Failed to load the customer's points");
      })
    },

    getSettings() {
      this.$simplecater.get(`/settings`).then((r) => {
        this.settings = r.data.data.find((setting) => setting.key === 'POINTS_EXCHANGE_RATE');

        if (this.settings) {
          this.exchangeRate = this.settings.value;
        }
      }).catch(() => {
        this.$message.error("Failed to load the settings");
      })
    },

    onSubmit() {
      this.$refs.pointsForm.validate((valid) => {
        if (this.newPointsBalance < 0) { valid = false }

        if (valid) {
          this.updatePoints();
        } else {
          this.$message.error('Some validations failed, please check your inputs')
          return false;
        }
      })
    },

    updatePoints() {
      this.submitting = true;
      const params = { amount: parseInt(this.adjustPoints.addedPoints) };

      this.$simplecater.put(`/points/customers/${this.$route.params.id}`, params).then((r) => {
        this.points = r.data.data;
        this.submitting = false;
        this.adjustPoints.addedPoints = 0;
        this.refreshActivities++;
        this.$message("Points have been updated");
      }).catch(() => {
        this.submitting = false;
        this.$message.error("Failed to update the points");
      })
    },

    formatPointsIntoMoney(points) {
      const fixedCents = (points / this.exchangeRate).toFixed(2);

      return `$${fixedCents.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
    },

    formatNumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },

  computed: {
    newPointsBalance() {
      const addedPoints = parseInt(this.adjustPoints.addedPoints) || 0;

      return this.formatNumber(this.points.amount + addedPoints);
    }
  },

  created() {
    this.getCustomer();
    this.getPoints();
    this.getSettings();
  },

  components: {
    CustomerRewardsActivities,
  },
}
</script>

<style lang="sass">
.customer-points
  .customer-name
    display: inline-block
    font-weight: 600

  .points-balance
    border-bottom: 1px solid #ebeef5
    font-size: 32px
    font-weight: 600
    line-height: 38.4px
    text-align: left

  .amount
    padding: 19px 39px 22px 24px
    border-right: 1px solid #ebeef5

  .dollars
    padding: 19px 39px 22px 24px
    border-left: 1px solid #ebeef5

  h3
    margin: 12px 0 0 0
    font-size: 16px
    font-weight: 600
    line-height: 19.2px
    text-align: left
    color: #a9a9a9

.points-form
  .row
    width: 100%

    label
      text-align: left
      font-family: Open Sans
      font-size: 16px
      font-weight: 600
      color: #888888
      
    input
      font-family: Open Sans
      font-size: 20px
      font-weight: 600
      line-height: 24px
      text-align: center
      color: #606266
      height:48px

    .info
      font-family: Open Sans
      font-size: 20px
      font-weight: 600
      line-height: 24px
      text-align: center
      color: #606266
      float: right
      width:108px
      height:48px

  .points-button
    width: 144px
    height: 44px
    font-family: Open Sans
    font-size: 16px
    font-weight: 600
    line-height: 20px
    color: #ffffff


</style>