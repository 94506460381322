<template lang='pug'>
el-dialog(
  v-if='visibleDialog'
  title='Your order is almost complete',
  :visible.sync='visibleDialog')       
  p(v-if='!isStaff' class="paragraph") Your order will be submitted. Our team will review the order details and confirm it as soon as possible. Payment for the corresponding total will be processed between 24 to 48 hours before the event.
  el-form(
    :label-width='$labelWidth',
    label-position='right', ref='form'
    :model='accept_body', :rules='rules'
    )

    // TYPE
    el-form-item(label='Payment source', prop='payment_source', class='choose-card')
      el-select(
        v-model='accept_body.payment_source',
        placeholder='Select an option',
        v-loading='sources == null'
      )
        el-option(
          :label='item.name',
          :value='item.id',
          v-for='item in sources',
          :key='item.id'
        )

    el-form-item(v-if='!isStaff' label='', class='add-card')
      template
        span Or&nbsp;
          router-link(to='/user/billing') add a new card

    // NOTES
    el-form-item(label='Notes', prop='customer_notes')
      el-input(
        v-model='accept_body.customer_notes',
        placeholder='Something we should know?',
        type='textarea'
      )

    el-form-item(prop='customer_notes')
      el-checkbox(v-model='activePOField')
        | Add PO# to Invoice

    
    el-form-item(v-if='activePOField' label='Invoice PO#', prop='customer_notes')
      el-input(
        v-model='accept_body.customer_po',
        placeholder='Invoice number',
        type='text'
      )

    el-row()
      el-col(:span='8')
        .top-column
          .top-title DELIVERY SUMMARY
          div
          .standout {{ 'Del Date: '}}
            span.standout-accent {{ $formatDate(this.request.delivery_date) }}
          .standout {{ 'Del Time: ' }}
            span.standout-accent {{ $formatTime(this.request.delivery_time) }}
          .standout {{ 'Serves: ' }}
            span.standout-accent {{ this.request.people }} People
          .standout(v-if='this.estimate.vendor_name') {{ 'From: ' }}
            span.standout-accent {{ this.estimate.vendor_name }} 

      el-col(:span='8')
        .top-column
          .top-title DELIVERY ADDRESS
          div
            template(v-if='address !== null')
              .standout
                span.standout-accent {{ this.address.address_line_1 }}
              template(v-if='this.request!== null && this.request.suite')
               .standout
                span.standout-accent {{ this.request.suite }}
              template(v-else)
               .standout
                span.standout-accent {{ this.address.address_line_2 }}
              .standout
                span.standout-accent  {{this.address.city}}, {{this.address.state}} {{this.address.zip_code}}
    el-form-item( label='' prop='confirm_data' class="reset")
        el-checkbox( v-model='accept_body.confirm_data' )
          | I confirm the above  delivery details
            
  div.dialog-footer(slot='footer')
    // BUTTONS
    .buttons-section
      el-button(type='primary' @click='onSubmit', :loading='submitting')
        | Submit for approval
      el-button(@click='onCancel')
        | Cancel
</template>


<script>

function data() {
  return {
    isStaff: this.$route.matched[0].path === '/staff',
    visibleDialog: this.visible,
    submitting: false,
    sources: null,
    rules: {
      confirm_data: [
        { type: "enum", enum: ["true"], required: true, message: "*please confirm, or cancel and notify simplecater", transform: value => value.toString() },
      ],
      payment_source: [
        { required: true, message: 'Please select Payment source', trigger: 'change' }
      ],
    },
    activePOField: false,
    accept_body: {
      confirm_data: false,
      payment_source: null,
      customer_notes: '',
      customer_po: '',
      tips: 0,
      used_points: 0,
      points_price: 0
    }
  }
}

function acceptEstimate() {
  this.accept_body.tips = +this.tipsCent || 0
  this.accept_body.used_points = +this.estimate.usedPoints || 0
  this.accept_body.points_price = +this.estimate.pointsPrice || 0
  const target = `/estimates/${this.estimate.id}/accept`

  this.$simplecater.post(target, this.accept_body).then(() => {
    this.$message(this.customerId ? 'The estimate has been accepted' : 'The estimate has been accepted and we will take care of your order');
    this.$bus.$emit('estimate-accepted');
    this.updateRequests();
    this.visibleDialog = false;
  }).catch(() => {
    this.$message.error("Failed to accept the estimate")
  })
}

function getSources() {
  const apiUrl = this.customerId ? `/customers/${this.customerId}/cards` : '/cards';
  return this.$simplecater.get(apiUrl).then((r) => {
    this.sources = r.data.data
  })
}

function getUserData() {
  this.accept_body = {
    confirm_data: false,
    payment_source: null,
    customer_notes: '',
    customer_po: this.request.customer_po || '',
    tips: this.request.tips || 0,
    used_points: this.estimate.usedPoints || 0,
    points_price: this.estimate.pointsPrice || 0
  }
}

function onCancel() {
  this.$emit('update:visible', false)
}

function updateRequests() {
  this.$emit('updateRequests');
}

function onSubmit() {
  this.$refs.form.validate((valid) => {
    if (valid) {
      this.acceptEstimate()
    } else {
      this.$message.error('Some validations failed, please check your inputs')
      return false;
    }
  })
}

const watch = {
  visibleDialog: function (newValue) {
    this.$emit('update:visible', newValue)
  },
  visible: function (newValue) {
    this.visibleDialog = newValue
  }
}

function created() {
  this.getSources();
  this.getUserData();
}

const methods = {
  acceptEstimate, onSubmit, onCancel, getSources, getUserData, updateRequests
}

const props = {
  estimate: { required: true },
  customerId: { required: false },
  visible: { required: true },
  request: { required: true },
  address: { required: true },
  contact: { require: false },
  tipsCent: { require: false },
}

export default { methods, data, watch, props, created }
</script>

<style lang="sass">
  .choose-card
    margin-bottom: 0.5rem
  .add-card
    margin: 0 0 1.2rem
  p.paragraph
    margin-top: 0
    margin-bottom: 1rem
  .el-dialog__body
    padding-top: 0.5rem
  .standout
    font-weight: 600
    color: #444
    margin-left: 22px
  .standout-accent
    color: $colorPrimary
  .top-column
    width: 240px
    padding: 10px
    margin-top: 35px
    border-radius: 11px
  .top-title
    font-size: 17px
    padding-bottom: 4px
  .reset .el-form-item__content
    margin-left: 0px !important 
</style>
