<template lang="pug">
el-card
  div(slot='header')
    i.mat-icon star
    | Points Activity

  el-table(:data='activities')
    el-table-column(prop='date', label='Date')
    el-table-column(prop='activity', label='Activity')
    el-table-column(prop='amount', label='Amount')
    el-table-column(prop='balance', label='Balance')
    el-table-column(prop='changed_by', label='Changed by')

  el-col
    el-pagination( 
      v-if="pagination.pages > 1"
      @current-change="onPaginationChange"
      :page-size="pagination.per_page", 
      :pager-count="5", 
      layout="prev, pager, next", 
      :total="pagination.total")
</template>

<script>
export default {
  name: 'CustomerRewardsActivities',

  data() {
    return {
      activities: null,
      pagination: {
        per_page: null,
        current: 1,
        pages: null,
        total: null,
      }
    }
  },

  methods: {
    async getPointsActivities(current_page) {
      let params = current_page ? { params: { page: current_page } } : {};
      
      await this.$simplecater.get(`/points/customers/${this.$route.params.id}/activities`, params).then((r) => {
        const { data, pagination } = r.data;
        this.activities = data.map((activity) => {
          activity.date = activity.date.split(' ')[0];
          
          return activity;
        });

        this.pagination = pagination;
      }).catch(() => {
        this.$message.error("Failed to load the customer point activities");
      });
    },

    onPaginationChange(current_page) {
      this.getPointsActivities(current_page);
    }
  },

  created() {
    this.getPointsActivities(this.pagination.current);
  },
}
</script>

<style lang="saas">
</style>