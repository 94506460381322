<template lang='pug'>
div
  el-row(:gutter='$rowGutter')

    el-col(:span='24')
      el-card
        div(slot='header')
          i.mat-icon playlist_add_check
          | Items
        template(v-if='fullInvoice !== null')
          el-table(:data='fullInvoice.items')
            el-table-column(prop='name', label='Name')
            el-table-column(prop='quantity', label='Qty.')
            el-table-column(prop='sale_price_cents', label='Unit price')
              template(slot-scope='scope')
                | {{$formatCents(scope.row.sale_price_cents) }}
            el-table-column(prop='total_cents', label='Price')
              template(slot-scope='scope')
                | {{ scope.row.type == 'POINTS' ? '-' : '' }}{{$formatCents(scope.row.total_cents) }}
</template>

<script>

function data() {
  return {
    fullInvoice: null
  }
}

function loadInvoice() {
  const target = `/invoices/${this.invoiceSummary.id}`
  // TODO catch
  return this.$simplecater.get(target).then((r) => {
    console.log("FULL REQUEST LOADED")
    this.fullInvoice = this.$mergeWithTips(r.data.data.tips, r.data.data)
    this.cuisines = this.fullInvoice.cuisines
    this.estimates = this.fullInvoice.estimates
  }).catch(() => {
    this.$message.error("Failed to load the invoice")
  })
}

function created() {
  this.loadInvoice()
}

const props = {
  invoiceSummary: {
    required: true, type: Object
  }
}

const methods = {
  created, loadInvoice
}

export default { created, data, methods, props }
</script>
