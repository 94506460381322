<template lang="pug">
  .main-container
    .view-content
      h2.page-title Rewards

      el-card
        .user-points-dashboard
          .user-points
            h2 {{ formatNumber(points) }}
            | Points

          .user-money
            h2 {{ formatPointsIntoMoney(points) }}
            | {{ exchangeRate }} points = $1 of rewards

      el-card
        .use-points-options
          h2 How would you like to use your points?
          .options
            .option.inavtive
                div
                  el-tooltip(content="Coming soon", placement="top", effect="light")
                    img(src="../assets/giftcard-100x-100.svg" alt="Get a gift card", width="150", height="150")
                
                el-tooltip(content="Coming soon", placement="top", effect="light")
                  <el-button type="primary" disabled>Get a gift card</el-button>
            .option
              div
                img(src="../assets/rewards-towards-catering.svg" alt="Towards a catering", width="150", height="150")
              <router-link to="/user/requests/new">
                <el-button type="primary">Towards a catering</el-button>
              </router-link>
      
      h3.section-title Points Activity
      el-card
        user-rewards-activities

      el-row(:gutter="20")
        el-col(:span="12")
          h3.section-title Earn Points
          el-card.rewards-list
            ul
              li Earn at least 1 point for every $1 spent on food products from participating vendors.
              li Points will be credited to your account once the order payment has been processed.
              li Points expire 1 year after your last order.
              li SimpleCater may adjust points for unusual circumstances.
              li Points are non-transferable.
        el-col(:span="12")
          h3.section-title Spend Points
          el-card.rewards-list
            ul
              li Use points as credit for caterings from eligible vendors.
              li Estimates will display your "max eligible" points, indicating how many you can redeem.
              li If your order is reduced, the points used will adjust to the new "max eligible" amount.
              li Points can also be used for digital gift cards, subject to a minimum redemption amount.
              li Points are not redeemable for cash or checks
</template>

<script>
import UserRewardsActivities from './UserRewardsActivities.vue';

export default {
  name: 'UserRewards',

  data() {
    return {
      account: null,
      points: 0,
      exchangeRate: 0,
    }
  },

  methods: {
    getAccount() {
      this.$simplecater.get('/account').then((r) => {
        this.account = r.data.data

        if(!this.account.rewards) { this.$router.push('/user/overview') }
      }).catch(() => {
        this.$message.error("Failed to load the account information")
        this.$router.push('/')
      })
    },

    getPoints() {
      this.$simplecater.get(`points/`).then((r) => {
        this.points = r.data.data.amount;
      }).catch(() => {
        this.$message.error("Failed to load the customer's points");
      })
    },

    getSettings() {
      this.$simplecater.get(`/settings`).then((r) => {
        let settings = r.data.data.find((setting) => setting.key === 'POINTS_EXCHANGE_RATE');

        if (settings) {
          this.exchangeRate = settings.value;
        }
      }).catch(() => {
        this.$message.error("Failed to load the settings");
      })
    },

    formatPointsIntoMoney(points) {
      const fixedCents = (points / this.exchangeRate).toFixed(2);

      return `$${fixedCents.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
    },

    formatNumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },

  created() {
    this.getAccount();
    this.getPoints();
    this.getSettings();
  },

  components: {
    UserRewardsActivities
  }
}
</script>

<style scoped lang="sass">
  .sub-header
    display: flex
    flex-direction: row
    justify-content: space-between
    color: #1F1F18

  .section-title
    font-family: Inter
    font-size: 16px
    font-weight: 500
    line-height: 24px
    letter-spacing: -0.03em
    text-align: left
    color: #A2A29D
    margin: 40px 0 20px 0

  .user-points-dashboard
    display: flex
    flex-direction: row
    justify-content: space-between
    width: 100%
    gap: .9375rem
    font-size: 16px
    line-height: 24px
    letter-spacing: -0.03em
    text-align: center
    color: #6E6E68

    h2
      font-size: 2.5rem
      font-family: Inter
      font-size: 36px
      font-weight: 500
      line-height: 36px
      letter-spacing: -0.05em
      color: #1F1F18
      margin: 0 0 0.5rem 0

    .user-points
      width: 100%
      padding: 1.5rem
      background: #F1F1E3
      border-radius: 12px

    .user-money
      width: 100%
      padding: 1.5rem
      background-color: #FAFAF5
      border-radius: 12px

  .use-points-options
    h2
      font-family: Inter
      font-size: 20px
      font-weight: 500
      line-height: 20px
      letter-spacing: -0.03em
      text-align: center
      color: #1F1F18
      margin: 0

  .options
    display: flex
    flex-direction: row
    justify-content: space-between
    gap: 1.25rem
    margin-top: 1.25rem

    .option
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      width: 100%
      padding: 1.5rem
      background: #FAFAF5
      border-radius: 12px
      text-align: center

      .el-button
        padding: 12px 26px
        gap: 8px
        max-width: 178px
        background: #125F43
        border-color: #125F43
        border-radius: 12px
        margin-top: 14px
        text-align: center
    
    .inavtive
      color: #6E6E68
      cursor: not-allowed

  .rewards-list
    ul li
      font-family: Inter
      font-size: 14px
      font-weight: 500
      line-height: 20px
      letter-spacing: -0.03em
      text-align: left

</style>