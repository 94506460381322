import { render, staticRenderFns } from "./UserRequestDetails.vue?vue&type=template&id=a3aacfb0&scoped=true&"
import script from "./UserRequestDetails.vue?vue&type=script&lang=js&"
export * from "./UserRequestDetails.vue?vue&type=script&lang=js&"
import style0 from "./UserRequestDetails.vue?vue&type=style&index=0&id=a3aacfb0&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3aacfb0",
  null
  
)

export default component.exports